<style>
.v-list--dense .v-list-item{
  width: 33%;
  min-height: 50px;
  display: block;
}
.v-list--dense{
  padding: 0 !important;
}
.v-list--dense .v-item-group{
  display: flex;
}
.v-list--dense .v-list-item__icon{
  display: block;
  margin-bottom: 0px !important;
  text-align: center;

}
.v-list--dense .v-list-item__content{
  padding: 2px 2px 10px 2px !important;
  text-align: center;
}
.v-list--dense .v-list-item__title{
  font-size: .95rem !important;
  font-weight: 700 !important;
  line-height: 1.2rem !important;
}
</style>
<template>
  <div >
    <v-list dense>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        style="direction: rtl !important;"
      >
        <v-list-item
          v-for="item in items"
          :key="item.keyItem"
          @click="tabClick(item.rout)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue'
import router from '@/router'
import ModelService from '@/services/model.service'

export default {
  components: {
    BLink, BButton,
  },
  data: () => ({
    selectedItem: 0,
    instagramAddress: 'https://www.instagram.com/oskooei.amir/',
    items: [
      {
        keyItem: 3, text: 'ایده ها', icon: 'mdi-lightbulb-on', rout: 'home',
      },
      {
        keyItem: 2, text: 'اینستاگرام', icon: 'mdi-instagram', rout: 'instagram',
      },
      {
        keyItem: 4, text: 'درباره', icon: 'mdi-information', rout: 'profile',
      },
    ],
  }),
  mounted() {
    ModelService.getInfo().then(
        res => {
          console.log(res)
          this.instagramAddress = res.instagramAddress
        },
    )
  },
  methods: {
    tabClick(rout) {
      if (rout === 'home') {
        this.$emit('on-update-Footer-Select', 'home')
        this.selectedItem = 0
        this.listDevice = false
      }
      if (rout === 'profile') {
        this.$emit('on-update-Footer-Select', 'profile')
        this.selectedItem = 3
      }
      if (rout === 'instagram') {
        window.location.href = this.instagramAddress
     //   window.location.href = 'instagram://user?username=oskooei.amir'
      //  this.$emit('on-update-Footer-Select', 'dashboard')
       // this.selectedItem = 2
      }
      /// router.push({ name: rout }).catch(err => {})
    },
    monitoring() {
      router.push({ name: 'home' }).catch(err => {})
    },
  },
}
</script>
<style>
[dir=rtl] .footer .v-list-item--active .v-icon  {
  /*color: #33df5ec4 !important;*/
  /*font-size: 30px !important;*/
}
.footer .v-list--dense .v-list-item {
  min-height: unset;
}

.footer{
  bottom: 0px !important;
  position: absolute;
  width: 100%;
  border-top: 1px solid rgb(206, 206, 206);
  z-index: 99999;
  right: 0;
}
.footer-home .v-list--dense .v-list-item__title{
  font-size: .9rem !important;
  font-weight: 500 !important;
  line-height: 0.8rem !important;
  color: #828181;
}
[dir=rtl] .footer-home .v-list-item--active .v-icon,[dir=rtl] .footer-home .v-list-item--active .v-list-item__title  {
  color: #0087ff !important;
  /*font-size: 30px !important;*/
}
</style>
